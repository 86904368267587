const selectTheme = {
  defaultProps: {
    variant: 'outlined',
    color: 'green',
    size: 'md',
    label: 'Label',
    error: false,
    success: false,
    arrow: undefined,
    value: undefined,
    onChange: undefined,
    selected: undefined,
    offset: 5,
    dismiss: {},
    animate: {
      unmount: {},
      mount: {},
    },
    autoHeight: false,
    lockScroll: false,
    labelProps: {},
    menuProps: {},
    className: '',
    disabled: false,
    containerProps: undefined,
  },
  valid: {
    variants: ['outlined'],
    sizes: ['md', 'lg'],
    colors: ['green'],
  },
  styles: {
    base: {
      container: {
        position: 'relative',
        height: 'h-[48px] lg:h-[64px]',
        width: 'sm:min-w-[350px] w-full',
        selfFtretch: 'self-stretch',
      },
      select: {
        peer: 'peer',
        width: 'w-full',
        height: 'h-full',
        bg: 'bg-transparent',
        color: 'text-blue-gray-700',
        fontFamily: 'font-sans',
        fontWeight: 'font-normal',
        textAlign: 'text-left',
        outline: 'outline outline-0 focus:outline-0 focus:!border-leaf',
        disabled: 'disabled:bg-transparent disabled:border-1',
        transition: 'transition-all',
      },
      arrow: {
        initial: {
          display: 'grid',
          placeItems: 'place-items-center',
          position: 'absolute',
          top: 'top-2/4',
          right: 'right-2',
          pt: 'pt-px',
          width: 'w-5',
          height: 'h-5',
          color: 'text-blue-gray-400',
          transform: 'rotate-0 -translate-y-2/4',
          transition: 'transition-all',
        },
        active: {
          transform: 'rotate-180',
          mt: 'mt-px',
        },
      },
      label: {
        display: 'flex',
        width: 'w-full',
        height: 'h-full',
        userSelect: 'select-none',
        pointerEvents: 'pointer-events-none',
        position: 'absolute',
        left: 'left-0',
        fontWeight: 'font-normal',
        transition: 'transition-all',
      },
      menu: {
        width: 'w-full',
        maxHeight: 'max-h-96',
        bg: 'bg-white',
        p: 'p-3',
        top: '!top-[47px] lg:!top-[63px]',
        border: 'border border-blue-gray-50',
        borderRadius: 'rounded-md',
        boxShadow: 'shadow-lg shadow-blue-gray-500/10',
        fontFamily: 'font-sans',
        fontSize: 'text-sm',
        fontWeight: 'font-normal',
        color: 'text-blue-gray-500',
        overflow: 'overflow-auto',
        outline: 'focus:outline-none',
      },
      option: {
        initial: {
          pt: 'pt-[9px]',
          pb: 'pb-2',
          px: 'px-3',
          borderRadius: 'rounded-md',
          lightHeight: 'leading-tight',
          cursor: 'cursor-pointer',
          userSelect: 'select-none',
          background: 'hover:bg-blue-gray-50 focus:bg-blue-gray-50',
          opacity: 'hover:bg-opacity-80 focus:bg-opacity-80',
          color: 'hover:text-blue-gray-900 focus:text-blue-gray-900',
          outline: 'outline outline-0',
          transition: 'transition-all',
        },
        active: {
          bg: 'bg-blue-gray-50 bg-opacity-80',
          color: 'text-blue-gray-900',
        },
        disabled: {
          font: 'disabled:text-red-100',
          opacity: 'opacity-50',
          cursor: 'cursor-not-allowed',
          userSelect: 'select-none',
          pointerEvents: 'pointer-events-none',
        },
      },
    },
    variants: {
      outlined: {
        base: {
          select: {},
          label: {
            position: '-top-1.5',
            before: {
              content: "before:content[' ']",
              display: 'before:block',
              boxSizing: 'before:box-border',
              width: 'before:w-2.5',
              height: 'before:h-1.5',
              mt: 'before:mt-[6.5px]',
              mr: 'before:mr-1',
              borderRadius: 'before:rounded-tl-md',
              pointerEvents: 'before:pointer-events-none',
              transition: 'before:transition-all',
              disabled: 'peer-disabled:before:border-transparent',
            },
            after: {
              content: "after:content[' ']",
              display: 'after:block',
              flexGrow: 'after:flex-grow',
              boxSizing: 'after:box-border',
              width: 'after:w-2.5',
              height: 'after:h-1.5',
              mt: 'after:mt-[6.5px]',
              ml: 'after:ml-1',
              borderRadius: 'after:rounded-tr-md',
              pointerEvents: 'after:pointer-events-none',
              transition: 'after:transition-all',
              disabled: 'peer-disabled:after:border-transparent',
            },
          },
        },
        sizes: {
          md: {
            container: {
              height: 'h-10',
            },
            select: {
              fontSize: 'text-sm',
              px: 'px-3',
              py: 'py-2.5',
              borderRadius: 'rounded-[7px]',
              states: {
                open: {},
              },
            },
            label: {
              initial: {},
              states: {
                close: {},
                open: {
                  lineHeight: 'leading-[4.25] lg:leading-[5.25]',
                },
                withValue: {
                  lineHeight: 'leading-tight',
                },
              },
            },
          },
          lg: {},
        },
        colors: {
          select: {
            green: {
              close: {
                borderColor: 'border-grey-480 disabled:border-grey-300',
              },
              open: {
                borderColor: 'border-leaf border',
                borderTopColor: '!border-t-transparent',
                border: 'rounded-b-none',
                text: '[&_span]:hidden',
              },
              withValue: {
                showLabel: 'active',
                borderColor: 'border-leaf',
                borderTopColor: 'border-t-transparent',
              },
            },
          },
          label: {
            green: {
              close: {
                color: 'text-blue-gray-400',
                lineHeight: 'leading-[4.25] lg:leading-[5.25]',
                before: 'before:border-transparent',
                after: 'after:border-transparent',
              },
              open: {
                borderColor: 'border-leaf',
                lineHeight: 'leading-[4.25] lg:leading-[5.25]',
              },
              withValue: {
                showLabel: 'active',
                borderColor: '!border-transparent',
                color: 'text-blue-gray-400',
                before: {
                  bt: 'before:border-t-0',
                  bl: 'before:border-l-0',
                },
                after: {
                  bt: 'after:border-t-0',
                  br: 'after:border-r-0',
                },
              },
            },
          },
        },
        states: {
          close: {
            select: {
              borderWidth: 'border',
            },
            label: {
              fontSize: 'text-sm',
              disabled: 'peer-disabled:text-grey-300',
              before: {
                bt: 'before:border-t-transparent',
                bl: 'before:border-l-transparent',
              },
              after: {
                bt: 'after:border-t-transparent',
                br: 'after:border-r-transparent',
              },
            },
          },
          open: {
            select: {
              borderWidth: 'border',
              borderColor: 'border-t-transparent',
              bg: 'bg-white',
            },
            label: {
              fontSize: 'text-[14px]',
              disabled: 'peer-disabled:text-transparent',
              before: {
                bt: 'before:border-t-0',
                bl: 'before:border-l-0',
              },
              after: {
                bt: 'after:border-t-0',
                br: 'after:border-r-0',
              },
            },
          },
          withValue: {
            select: {
              borderWidth: 'border',
              borderColor: '!border-t-transparent',
            },
            label: {
              fontSize: 'text-[11px]',
              disabled: 'peer-disabled:text-transparent',
              before: {
                bt: 'before:border-t',
                bl: 'before:border-l',
              },
              after: {
                bt: 'after:border-t',
                br: 'after:border-r',
              },
            },
          },
        },
        error: {
          select: {
            initial: {},
            states: {
              close: {},
              open: {
                border: 'rounded-b-none border',
                text: '[&_span]:hidden',
              },
              withValue: {},
            },
          },
          label: {
            initial: {
              lineHeight: 'leading-[4.25] lg:leading-[5.25]',
            },
            states: {
              close: {},
              open: {
                // showLabel: 'active',
                // text: '[&_span]:block',
              },
              withValue: {
                lineHeight: 'leading-[4.25] lg:!leading-[1.25]',
                after: 'after:border-t-transparent',
                before: 'before:!border-t-transparent',
                showLabel: 'active',
              },
            },
          },
        },
        success: {},
      },
      standard: {},
      static: {},
    },
  },
};
export default selectTheme;
