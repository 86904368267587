'use client';

import { Providers } from 'app/providers';
import { LayoutProps } from 'types/next';

export default function SlugLayout({ children, params }: LayoutProps) {
  const { locale } = params;

  return (
    <html lang={locale}>
      <body>
        <Providers>{children}</Providers>
      </body>
    </html>
  );
}
